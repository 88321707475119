import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Summary Statistics`}</h1>
    <ul>
      <li parentName="ul">{`The Polis system displays summary statistics about overall participation in a `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{``}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/monitor",
          "title": "monitor"
        }}>{`monitor`}</a>{` view displays:`}
        <ul parentName="li">
          <li parentName="ul">{`How many total `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` cast at least one `}<a parentName="li" {...{
              "href": "/vote",
              "title": "vote"
            }}>{`vote`}</a>{``}</li>
          <li parentName="ul">{`How many total `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{` were submitted`}</li>
          <li parentName="ul">{`How many total `}<a parentName="li" {...{
              "href": "/votes",
              "title": "votes"
            }}>{`votes`}</a>{` were cast`}</li>
          <li parentName="ul">{`Average `}<a parentName="li" {...{
              "href": "/votes",
              "title": "votes"
            }}>{`votes`}</a>{` per `}<a parentName="li" {...{
              "href": "/Participant",
              "title": "Participant"
            }}>{`Participant`}</a>{``}</li>
          <li parentName="ul">{`A graph of total `}<a parentName="li" {...{
              "href": "/Voters",
              "title": "Voters"
            }}>{`Voters`}</a>{` and `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` over time`}</li>
          <li parentName="ul">{`A graph of total `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{` over time`}</li>
          <li parentName="ul">{`A graph of total `}<a parentName="li" {...{
              "href": "/votes",
              "title": "votes"
            }}>{`votes`}</a>{` over time`}</li>
          <li parentName="ul">{`A distribution of `}<a parentName="li" {...{
              "href": "/votes",
              "title": "votes"
            }}>{`votes`}</a>{` across `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{``}
            <ul parentName="li">
              <li parentName="ul">{`ie., 0 `}<a parentName="li" {...{
                  "href": "/participants",
                  "title": "participants"
                }}>{`participants`}</a>{` `}<a parentName="li" {...{
                  "href": "/Voted",
                  "title": "Voted"
                }}>{`Voted`}</a>{` on all 600 statements, while 450 `}<a parentName="li" {...{
                  "href": "/participants",
                  "title": "participants"
                }}>{`participants`}</a>{` `}<a parentName="li" {...{
                  "href": "/Voted",
                  "title": "Voted"
                }}>{`Voted`}</a>{` 27 times`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/report",
          "title": "report"
        }}>{`report`}</a>{` view displays many of the same summary statistics, and additionally:`}
        <ul parentName="li">
          <li parentName="ul">{`How many `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` were `}<a parentName="li" {...{
              "href": "/grouped",
              "title": "grouped"
            }}>{`grouped`}</a>{`, which specifically means `}<a parentName="li" {...{
              "href": "/voted",
              "title": "voted"
            }}>{`voted`}</a>{` more than the `}<a parentName="li" {...{
              "href": "/minimum-voting-threshold",
              "title": "minimum voting threshold"
            }}>{`minimum voting threshold`}</a>{``}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      